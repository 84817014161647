const Card = {
	MuiCard: {
		styleOverrides: {
			root: {
				borderRadius: '20px',
			},
		},
	},
	MuiCardContent: {
		styleOverrides: {
			root: {
				padding: '16px',
			},
		},
	},
	MuiCardActions: {
		styleOverrides: {
			root: {
				padding: '0 16px 32px',
			},
		},
	},
}

export default Card
