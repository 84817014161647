const Dialog = {
	MuiDialog: {
		styleOverrides: {
			root: {
				'&.SizeGuide-root .MuiDialog-paperWidthSm': {
					minHeight: 'auto',
					'& .MuiTableContainer-root.Table-root, & .MuiTableContainer-root.Table-root > .MuiTable-root': {
						transform: 'scaleX(-1) rotate(180deg)',
					},
				},
			},
		},
	},
}

export default Dialog
