/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const XIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M22.2 23.7L12 13.5 1.8 23.7c-.4.4-1 .4-1.4 0l-.1-.1c-.4-.4-.4-1 0-1.4L10.5 12 .3 1.8C-.1 1.4-.1.8.3.4L.4.3c.4-.4 1-.4 1.4 0L12 10.5 22.2.3c.4-.4 1-.4 1.4 0l.1.1c.4.4.4 1 0 1.4L13.5 12l10.2 10.2c.4.4.4 1 0 1.4l-.1.1c-.2.2-.4.3-.7.3-.2 0-.5-.1-.7-.3z" />
	</svg>
))
XIconSvg.displayName = 'XIconSvg'

const XIcon = forwardRef((props, ref) => <Icon component={XIconSvg} ref={ref} {...props} />)
XIcon.displayName = 'XIcon'

XIcon.defaultProps = {
	...Icon.defaultProps,
}
XIcon.propTypes = {
	...Icon.propTypes,
}

export default XIcon
export { XIconSvg }
