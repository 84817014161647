/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const XCircleIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M20.5 3.5c-4.7-4.7-12.3-4.7-17 0s-4.7 12.3 0 17 12.3 4.7 17 0 4.7-12.3 0-17zM4.6 19.4C.5 15.3.5 8.6 4.6 4.5s10.8-4.1 14.9 0 4.1 10.8 0 14.9c-4.2 4.1-10.8 4.1-14.9 0zm11.6-4.2L13.1 12l3.2-3.2c.3-.3.3-.8 0-1.1-.3-.3-.8-.3-1.1 0L12 10.9 8.8 7.8c-.3-.3-.8-.3-1.1 0-.3.3-.3.8 0 1.1l3.2 3.2-3.2 3.2c-.3.3-.3.8 0 1.1.3.3.8.3 1.1 0l3.2-3.2 3.2 3.2c.3.3.8.3 1.1 0 .2-.5.2-.9-.1-1.2z" />
	</svg>
))
XCircleIconSvg.displayName = 'XCircleIconSvg'

const XCircleIcon = forwardRef((props, ref) => <Icon component={XCircleIconSvg} ref={ref} {...props} />)
XCircleIcon.displayName = 'XCircleIcon'

XCircleIcon.defaultProps = {
	...Icon.defaultProps,
}
XCircleIcon.propTypes = {
	...Icon.propTypes,
}

export default XCircleIcon
export { XCircleIconSvg }
