import { fonts } from '../fonts'
import { alpha } from '@mui/system'

export const buttonFontSize = 14
export const buttonIconFontSizeMedium = 20

const Button = {
	MuiButton: {
		styleOverrides: {
			root: {
				fontFamily: fonts.regular,
				fontSize: buttonFontSize,
				letterSpacing: '0.5px',
			},
			sizeMedium: {
				'& .MuiButton-iconSizeMedium.MuiButton-startIcon': {
					'& .MuiSvgIcon-fontSizeMedium': {
						fontSize: buttonIconFontSizeMedium,
					},
				},
			},
			text: {
				fontWeight: 700,
			},
			containedSecondary: {
				color: '#141616',
				minHeight: 60,
				'&:hover': {
					boxShadow: 'none',
					backgroundColor: '#f4ebd4',
				},
				'&:focus': {
					boxShadow: 'none',
					backgroundColor: '#F8F2E4',
				},
				'&:active': {
					boxShadow: 'none',
					backgroundColor: '#F8F2E4',
				},
				'&.Mui-disabled': {
					color: '#141616',
					backgroundColor: alpha('#F8F2E4', '0.2'),
				},
				'& .MuiButton-startIcon': {
					fill: '#141616',
				},
				'& >svg': {
					color: '#141616',
				},
				'& .MuiButton-endIcon': {
					fill: '#141616',
				},
			},
			contained: {
				borderRadius: 30,
			},
			outlined: {
				borderRadius: 30,
			},
			textNoLinkPrimary: {
				fontFamily: fonts.redHatDisplay,
			},
		},
	},
}

export default Button
