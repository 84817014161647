const IconButton = {
	MuiIconButton: {
		styleOverrides: {
			colorSecondary: {
				backgroundColor: 'transparent',
				color: '#141616',
				'&:hover': {
					backgroundColor: 'transparent',
				},
				'&:active': {
					backgroundColor: 'transparent',
				},
				'&:focus': {
					backgroundColor: 'transparent',
				},
				'&.Mui-disabled': {
					color: '#141616',
					// backgroundColor: alpha('#F8F2E4', '0.2'),
				},
				'& .MuiCircularProgress-root': {
					color: '#141616',
				},
				'& .MuiSvgIcon-root': {
					color: '#141616',
				},
			},
		},
	},
}

export default IconButton
