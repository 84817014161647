const BHHeader = {
	BHHeader: {
		defaultProps: {
			menuProps: {
				eventType: 'hover',
				dropDownProps: {
					placement: 'bottom',
					style: { width: '90%', margin: '0 auto' },
				},
			},
		},
	},
}
export default BHHeader
