import { fonts } from '../fonts'

const CssBaseline = {
	MuiCssBaseline: {
		styleOverrides: {
			body: {
				fontFamily: fonts.redHatDisplay,
			},
		},
	},
}

export default CssBaseline
