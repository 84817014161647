/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const ArrowheadDownIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M2 7.4c0-.5.5-1 1-.9.2 0 .5.3.5.3l8.6 8.6 8.5-8.5c.3-.4.8-.4 1.2-.1.4.3.4.8.1 1.2l-.1.1-9.1 9.1c-.3.3-.9.3-1.2 0L2.3 8c-.2-.2-.3-.4-.3-.6z" />
	</svg>
))
ArrowheadDownIconSvg.displayName = 'ArrowheadDownIconSvg'

const ArrowheadDownIcon = forwardRef((props, ref) => <Icon component={ArrowheadDownIconSvg} ref={ref} {...props} />)
ArrowheadDownIcon.displayName = 'ArrowheadDownIcon'

ArrowheadDownIcon.defaultProps = {
	...Icon.defaultProps,
}
ArrowheadDownIcon.propTypes = {
	...Icon.propTypes,
}

export default ArrowheadDownIcon
export { ArrowheadDownIconSvg }
