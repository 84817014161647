/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const SearchIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M23.8 22.7l-7.3-7.3c3.3-4 2.8-9.9-1.2-13.2S5.5-.6 2.1 3.3s-2.8 9.9 1.2 13.2c3.5 3 8.7 2.9 12.2-.1l7.3 7.3c.3.3.8.3 1.1 0 .2-.2.2-.7-.1-1zM9.3 17.2c-4.3 0-7.9-3.5-7.9-7.9.1-4.3 3.6-7.8 8-7.8 4.3 0 7.9 3.5 7.9 7.9-.1 4.3-3.6 7.8-8 7.8z" />
	</svg>
))
SearchIconSvg.displayName = 'SearchIconSvg'

const SearchIcon = forwardRef((props, ref) => <Icon component={SearchIconSvg} ref={ref} {...props} />)
SearchIcon.displayName = 'SearchIcon'

SearchIcon.defaultProps = {
	...Icon.defaultProps,
}
SearchIcon.propTypes = {
	...Icon.propTypes,
}

export default SearchIcon
export { SearchIconSvg }
