const BHRecentlyViewed = {
	BHRecentlyViewed: {
		defaultProps: {
			showTitle: false,
			skeletonProps: { columnGap: 40 },
			sliderProps: {
				breakpoints: {
					xs: {
						spaceBetween: 10,
					},
					md: {
						spaceBetween: 20,
					},
				},
				controlsPosition: 'top-right',
				spaceBetween: 20,
			},
		},
	},
}

export default BHRecentlyViewed
