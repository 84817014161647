/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const NotificationsIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M21.43 20.535H2.53c-.6 0-1.2-.3-1.5-.8-.3-.5-.3-1.2.1-1.7l1-1.5c1.6-2.3 2.4-5.1 2.4-7.9 0-3.2 2-6 5.1-7.1.7-1.3 2.4-1.8 3.7-1.1.5.3.9.6 1.1 1.1 3 1 5 3.9 5.1 7.1 0 2.8.8 5.6 2.4 7.9l1 1.5c.3.5.4 1.2.1 1.7-.4.5-1 .8-1.6.8zm-19.2-1.9c-.1.1-.1.3 0 .4.1.1.2.2.4.2h18.9c.1 0 .3-.1.4-.2.1-.1.1-.3 0-.4l-1-1.5c-1.7-2.6-2.7-5.6-2.6-8.7 0-2.7-1.8-5.2-4.5-5.9-.2-.1-.3-.2-.4-.4-.3-.7-1.2-1.1-1.9-.8-.3.1-.6.4-.8.8-.1.2-.2.3-.4.4-2.7.8-4.5 3.2-4.5 6 0 3.1-.9 6.1-2.6 8.7l-1 1.4z" />
		<path d="M12.03 24.035c-2.3 0-4.1-1.9-4.1-4.1 0-.4.3-.6.6-.6s.6.3.6.6c0 1.6 1.3 2.9 2.9 2.9 1.6 0 2.9-1.3 2.9-2.9 0-.4.3-.6.6-.6s.6.3.6.6c0 2.2-1.8 4.1-4.1 4.1z" />
	</svg>
))
NotificationsIconSvg.displayName = 'NotificationsIconSvg'

const NotificationsIcon = forwardRef((props, ref) => <Icon component={NotificationsIconSvg} ref={ref} {...props} />)
NotificationsIcon.displayName = 'NotificationsIcon'

NotificationsIcon.defaultProps = {
	...Icon.defaultProps,
}
NotificationsIcon.propTypes = {
	...Icon.propTypes,
}

export default NotificationsIcon
export { NotificationsIconSvg }
